const lang = {
  EN: {
    textProportion: 1 / 21,
    distanceProportion: 1 / 16,
  },
  FR: {
    textProportion: 1 / 23,
    distanceProportion: 1 / 17,
  },
  DE: {
    textProportion: 1 / 24,
    distanceProportion: 1 / 22,
  },
};

export const triangleTextProportions = {
  ['localhost']: {
    en: {
      ...lang.EN,
    },
    'en-GB': {
      ...lang.EN,
    },
    'de-DE': {
      ...lang.DE,
    },
    'fr-FR': {
      ...lang.FR,
    },
  },
  ['rentola.co.uk']: {
    'en-GB': {
      ...lang.EN,
    },
  },
  ['staging.rentola.co.uk']: {
    'en-GB': {
      ...lang.EN,
    },
  },
  ['rentola.com']: {
    en: {
      ...lang.EN,
    },
  },
  ['staging.rentola.com']: {
    en: {
      ...lang.EN,
    },
  },
};
